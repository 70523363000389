import styled from 'styled-components';
import { color, compose, space, height, opacity } from 'styled-system';
import propTypes from '@styled-system/prop-types';

const style = compose(
    color,
    height,
    opacity,
    space
);

const Breakline = styled.hr(style);

Breakline.propTypes = {
    ...propTypes.color,
    ...propTypes.height,
    ...propTypes.opacity,
    ...propTypes.space
};

export default Breakline;
