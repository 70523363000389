import Button from '../Button/Button';
import Notification from '../Notification/Notification';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from '../Forms';
import fetch from '../../utils/fetch';
import Flexbox from '../Flexbox';

const EmailSubmit = () => {
    const [email, setEmail] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await fetch.get('/api/csrf-cookie');

            await fetch.post('/api/submit-interest', {
                email,
            });
            setSuccess(true);
        } catch (error) {
            const response = error.response;
            // handles all non validation errors
            if (response.status !== 422) {
                return setErrorMessage(
                    'Something went wrong, please try again. If this continues please contact us.'
                );
            }
            // handles validation errors
            const backendErrors = Object.values(response.data.errors);
            setErrorMessage(backendErrors[0][0]);
        }
    };

    if (success) {
        return (
            <Flexbox justifyContent='center'>
                <Notification
                    borderColor='#dff0d8'
                    backgroundColor='#dff0d8'
                    fontSize='16px'
                    maxWidth='fit-content'
                    padding='8px'
                    justifyContent='center'
                    color='#3c763d'
                >
                    Your email has been submitted.
                </Notification>
            </Flexbox>
        );
    }
    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Input
                    id='email'
                    type='email'
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder='Enter your business email address'
                    name='email'
                ></Input>
                <Button type='submit' width={200} ml='16px'>
                    Submit
                </Button>
            </Form>
            <Flexbox justifyContent='center'>
                {errorMessage && (
                    <Notification
                        mt='24px'
                        fontSize='16px'
                        maxWidth='fit-content'
                        padding='8px'
                        justifyContent='center'
                    >
                        {errorMessage}
                    </Notification>
                )}
            </Flexbox>
        </>
    );
};

export default EmailSubmit;

const Form = styled.form`
    display: flex;
    justify-content: center;
    max-width: 600px;
    margin: 0 auto;
    height: 40px;
`;
