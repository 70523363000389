import { Heading1, Heading2, Paragraph } from '../components/Typography';
import React, { useMemo } from 'react';
import Breakline from '../components/Breakline';
import Grid from '../components/Grid';
import HeroSection from '../components/HeroSection';
import Link from '../components/Link';
import Media from '../components/Media';
import PropTypes from 'prop-types';
import SEO from '../components/seo';
import Section from '../components/Section';
import UncustomizedCard from '../components/Card';
import aboutPlaceholder from '../images/jacobs-about.jpg';
import defaultTheme from '../utils/themes';
import styled from 'styled-components';
import ypo from '../images/ypo.svg';
import EmailSubmit from '../components/EmailSubmit/EmailSubmit';

const AboutImage = styled.img.attrs({
	src: aboutPlaceholder,
	alt: 'About image',
})`
	max-width: 100%;
	box-sizing: border-box;
	object-fit: contain;
	position: relative;
	z-index: 10;
`;

const FrameworkHover = styled.button`
	background-color: transparent;
	border: 0px;
	cursor: pointer;
	outline: none;
	transition: all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1.2) 0s;

	:hover {
		transform: translateY(-3px);
	}
`;

const Card = styled(UncustomizedCard).attrs(({ paddingBottom }) => ({
	paddingTop: 32,
	paddingBottom: paddingBottom || 0,
	paddingLeft: 24,
	paddingRight: 24,
	width: '100%',
}))``;

const AboutPage = () => (
  <>
    <SEO
      title='About'
      description={`
                Jacobs are the leading Debt Recovery and Enforcement Services provider in the United Kingdom, with 60 years experience working with local authorities across the country.
                `}
    />
    <HeroSection paddingBottom='70px' paddingTop='8px'>
      <Heading1 marginTop='120px'>About Jacobs</Heading1>

      <Paragraph maxWidth='680px'>
        Jacobs have been established since 1959 and are now the largest
        independently owned enforcement agency in the country working
        extensively with councils throughout England and Wales and are the
        primary contractor in Wales for HMCTS.
      </Paragraph>
    </HeroSection>

    <Section backgroundColor='grey' paddingTop='100px'>
      <Grid
        gridTemplateRows={useMemo(
          () => ({
            xs: 'auto auto',
            md: 'auto',
          }),
          []
        )}
        gridTemplateColumns={useMemo(
          () => ({
            xs: '1fr',
            md: '1fr 1fr',
          }),
          []
        )}
        gridRowGap=''
        gridColumnGap='24px'
      >
        <div>
          <Paragraph>
            Jacobs has strategic direction and clear vision and core values that
            reflect the culture of our organisation of excellence and quality at
            the centre of all we do. We are focussed on driving up standards,
            performance and service delivery to clients and customers, year on
            year.
          </Paragraph>
          <Paragraph>
            Our forward-thinking approach for innovation ensures that Jacobs
            continually develop and improve all aspects of our services and
            provide unrivalled performance, progressive added value and
            efficiency for the council and social value for the community.
          </Paragraph>
          <Paragraph>
            Jacobs are at the very forefront of new technology and uniquely
            provide both clients and customers with digital self-service
            applications that are information rich and available live and on
            demand.
          </Paragraph>
        </div>
        <AboutImage />
      </Grid>

      <Breakline />

      <Heading2 fontSize='2.8rem'>Services</Heading2>

      <Paragraph>
        We are proud to be appointed on the following national solutions:
      </Paragraph>

      <div
        css={`
          margin-bottom: 40px;
          display: flex;
          align-items: center;
        `}
      >
        <FrameworkHover>
          <Link href='/ypo-framework'>
            <Media src={ypo} />
          </Link>
        </FrameworkHover>
      </div>

      <Grid
        ieCardLayout
        gridTemplateRows={useMemo(
          () => ({
            xs: 'auto auto auto auto auto auto auto auto auto auto auto',
          }),
          []
        )}
        gridTemplateColumns={useMemo(
          () => ({
            xs: '1fr',
            md: '1fr 1fr',
          }),
          []
        )}
        gridGap={useMemo(
          () => ({
            xs: '22px',
            md: '28px',
          }),
          []
        )}
      >
        <Card>
          <Heading2>Council Tax & Non Domestic Rates</Heading2>
          <Paragraph>
            Jacobs culture of high compliance stage collection focusses on
            dynamic case intelligence and maximising customer engagement
            throughout the process. With employed CIVEA and specialist trained
            staff we provide unrivalled and consistent quality, customer service
            and continuous improvement in collection performance year on year.
          </Paragraph>
        </Card>

        <Card>
          <Heading2>Parking & Road Traffic PCN</Heading2>
          <Paragraph>
            Experienced in both Parking and Bus Lane PCN collection we have
            tailored and flexible compliance stage collections through data
            intelligence and customer engagement initiatives. Dedicated
            specialist trained staff utilise persistent evader strategies, ANPR
            initiatives and the latest technology to maximise collection.
          </Paragraph>
        </Card>

        <Card>
          <Heading2>Corporate Debt Recovery</Heading2>
          <Paragraph>
            A tailored debt recovery strategy ensuring a single view of the
            customer to collect all corporate debt. With innovative case and
            data intelligence we maximise customer engagement through effective
            telephone, email, sms and visits in and out of office hours and
            weekends.
          </Paragraph>
        </Card>

        <Card>
          <Heading2>High Court Enforcement</Heading2>
          <Paragraph>
            Working in partnership with one of the country’s established and
            leading specialist High Court Enforcement Officers, Wilson & Roe,
            for a seamless service including no cost transfer service to the
            High Court and effective enforcement of all Writs of Control.
          </Paragraph>
        </Card>

        <Card>
          <Heading2>Commercial Rent Arrears Recovery (CRAR)</Heading2>
          <Paragraph>
            Ensuring the Landlord and Tenant relationship is maintained we
            collect all outstanding Commercial rent arrears through innovative
            recovery strategies and collection initiatives. Additional services
            can include Forfeiture returning the property to the Landlord where
            appropriate.
          </Paragraph>
        </Card>

        <Card>
          <Heading2>Customer Welfare</Heading2>
          <Paragraph>
            Jacobs are committed to effective case management, signposting and
            support for vulnerable customers. This is achieved through
            specialist staff training and dedicated Welfare Team managing all
            identified vulnerable cases. We pride ourselves on our long-standing
            working relationships with welfare advice agencies including our
            established third sector protocol.
          </Paragraph>
        </Card>

        <Card>
          <Heading2>Insolvency / Bankruptcy</Heading2>
          <Paragraph>
            Working in Partnership with Wilkin Chapman, a leading and innovative
            Solicitor and Insolvency Practitioners, we provide comprehensive
            case intelligence reports with specialist Insolvency, Bankruptcy and
            Charging Order further recovery options for councils to collect all
            outstanding debt including specialist advice on large, multiple and
            complex cases.
          </Paragraph>
        </Card>

        <Card>
          <Heading2>Partnership Working - Added Value Services</Heading2>
          <Paragraph>
            Jacobs work in partnership with clients and customers to deliver
            additional services and initiatives that create savings through
            system automation, reduced administration and increasing digital
            self-service for access to case information live and on demand.
            Jacobs are committed to promoting local measurable Social Value.
          </Paragraph>
        </Card>

        <Card>
          <Heading2>Approved Suppliers - Procurement Solutions</Heading2>
          <Paragraph>
            Jacobs demonstrate our services are both fit for purpose through
            securing a place on the YPO Enforcement Agency Services Solution and
            Crown Commercial Services Debt Resolution Service Framework.
          </Paragraph>
          <Paragraph>
            For more information, please contact us and our procurement team
            will provide further details and answer any questions you may have.
          </Paragraph>
        </Card>

        <Card>
          <Heading2>
            Warrants of Control, Arrest Warrants, Compensation and Confiscation
            Orders
          </Heading2>
          <Paragraph>
            Jacobs is contracted by His Majesty’s Courts and Tribunals Service
            (HMCTS), for enforcement services in relation to the collection of
            financial impositions and Community Penalty Breach Warrants.  Jacobs
            is the primary contractor in Wales.   The court fines we enforce
            include the following: Warrants of Control (for unpaid financial
            impositions); Arrest Warrants (Bail, No Bail, Breach and Committal);
            Compensation and Confiscation Orders.
          </Paragraph>
        </Card>
      </Grid>
    </Section>
    <Section backgroundColor='white' margin='80px auto'>
      <Heading1 fontSize='1.8rem' textAlign='center'>
        Interested in working with us?
      </Heading1>
      <Paragraph textAlign='center'>
        Please enter your <strong>gov.uk</strong> business email address and we
        will be in touch.
      </Paragraph>
      <EmailSubmit />
    </Section>
  </>
);

AboutPage.propTypes = {
	theme: PropTypes.object,
};

AboutPage.defaultProps = {
	theme: defaultTheme,
};

export default AboutPage;
